<template>
  <v-form lazy-validation v-model="valid" ref="form">
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>Name <span class="red--text">*</span></v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
            <v-text-field
              v-model="form.name"
              required
              outlined
              dense
              flat
              background-color="white"
              :rules="[v => !!v || 'Name is required']"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>User <span class="red--text">*</span></v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
            <v-autocomplete
              v-model="form.userId"
              item-text="name"
              item-value="id"
              outlined
              dense
              flat
              background-color="white"
              :items="users"
              :rules="[v => !!v || 'User is required']"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  name: "edit-setting-notification",
  props: {
    users: Array,
    form: Object,
  },
  data() {
    return {
      valid: true,
    };
  },
  methods: {
    async update() {
      if (this.$refs.form.validate()) {
        this.$store.commit("SET_LOADER", true);
        await this.$store
          .dispatch("settingNotification/update", this.form)
          .then(() => this.$store.commit("SET_LOADER", false))
          .catch(() => {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
            this.$store.commit("SET_LOADER", false);
          });
      } else {
        this.$store.dispatch("toast", {
          type: "error",
          message: "Form Harus Diisi semua",
        });
      }
    },
  },
};
</script>

<style></style>

<template>
  <form>
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>Name <span class="red--text">*</span></v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
            <span>{{ form.name }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>User <span class="red--text">*</span></v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
            <span>{{ userName(form.userId) }}</span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </form>
</template>

<script>
export default {
  name: "detail-setting-notification",
  props: {
    id: Number,
    users: Array,
    form: Object,
  },
  methods: {
    userName(id) {
      const result = this.users.find(x => x.id === id);
      return result ? result.name : "";
    },
  },
};
</script>

<style></style>
